<template>
  <BlokkliProvider v-bind="blokkli">
    <HomeHero
      v-if="slide"
      :title="slide.title"
      :text="slide.text"
      :bold-count="slide.boldTitleWords"
      :image="slide.image"
      :link="slide.link"
    />
    <Container>
      <div class="lg:w-8/12 node">
        <SplitTitle
          v-slot="{ top, bottom }"
          :title="node.title"
          :bold-count="node.titleWords"
        >
          <ContentHeader
            :title="top"
            :title-light="bottom"
            :lead="node.summary"
            :icon="node.icon"
          >
            <div
              v-if="node.body"
              class="content mt-20 md:mt-30 lg:mt-50"
              v-html="node.body"
            />
          </ContentHeader>
        </SplitTitle>
      </div>
    </Container>

    <Container class="mt-30 md:mt-50 lg:mt-60">
      <BlokkliField :list="node.paragraphs" name="field_paragraphs" />
    </Container>

    <Container>
      <BlokkliField
        :list="node.paragraphsFooter"
        name="field_paragraphs_footer"
        field-list-type="footer"
      />
    </Container>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import { definePageMeta } from '#imports'
import {
  HomeBoxFragment,
  NodePageFragment,
  HomeSlideFragment,
} from '#graphql-operations'

defineOptions({
  name: 'PageFphchHome',
})

definePageMeta({
  name: 'home',
  drupalRoute: true,
})

setBreadcrumbLinks()

provide('isFrontPage', true)

const nuxtRoute = useRoute()

// Get the data.
const { data: query } = await useAsyncData(nuxtRoute.path, () =>
  useCachedGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => v.data),
)

// Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<NodePageFragment>(query.value)

const { data: homeConfig } = await useAsyncData(() =>
  useCachedGraphqlQuery('home').then((v) => v.data.home),
)

const boxes = computed(() => {
  return (homeConfig.value?.homeParagraphs?.filter(
    (v) => v.__typename === 'ParagraphMultiButtonBox',
  ) || []) as HomeBoxFragment[]
})

provide('home_teasers', boxes)

const slide = computed(() => {
  return homeConfig.value?.homeParagraphs?.find(
    (v) => v.__typename === 'ParagraphImageTeaser',
  ) as HomeSlideFragment | undefined
})
await renderPageDependencies()
const blokkli = buildBlokkliProps(node)
</script>
