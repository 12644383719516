<template>
  <header class="relative mb-40 lg:mb-50">
    <div
      class="hero-image relative h-[230px] lg:h-[440px] overflow-hidden mx-auto max-w-[1660px]"
      :class="{ 'has-overlay': name !== 'pharmasuisse' }"
    >
      <MediaImage
        v-if="image"
        v-bind="image"
        :image-style="imageStyle"
        class="absolute w-full h-full overflow-hidden z-0"
      />
    </div>
    <div
      class="lg:absolute bottom-0 left-0 w-full lg:text-white mt-20 lg:mt-0 z-10"
    >
      <Container class="lg:-mb-25">
        <div class="max-w-hero">
          <SplitTitle
            v-slot="{ top, bottom }"
            :title="title"
            :bold-count="boldCount"
          >
            <h1
              v-if="title"
              class="text-3xl md:text-4xl lg:text-5xl xl:text-6xl mb-20 md:mb-30 font-semibold"
            >
              {{ top }}
              <span v-if="bottom" class="block font-normal">{{ bottom }}</span>
            </h1>
          </SplitTitle>
          <div
            class="font-normal text-md lg:text-lg mb-20 lg:mb-40"
            v-html="text"
          />
          <ButtonStyle v-slot="{ classes }">
            <FieldItemTypeLink v-bind="link" :class="classes" />
          </ButtonStyle>
        </div>
      </Container>
    </div>
  </header>
</template>

<script lang="ts" setup>
import type { LinkFragment, MediaImageFragment } from '#graphql-operations'

const { name } = useCurrentSite()

defineProps<{
  title: string
  text: string
  boldCount: number
  image: MediaImageFragment
  link: LinkFragment
}>()

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 420,
      height: 230,
    },
    md: {
      width: 800,
      height: 230,
    },
    lg: {
      width: 1024,
      height: 440,
    },
    xl: {
      width: 1660,
      height: 440,
    },
  },
})
</script>

<style lang="postcss">
.hero-image {
  &.has-overlay {
    &:after {
      content: '';
      @apply hidden lg:block h-3/4 absolute right-0 bottom-0 left-0 opacity-70 bg-gradient-to-t from-gray-900 to-transparent z-0;
    }
  }

  picture {
    @apply h-full;
  }

  img {
    @apply object-cover h-full;
  }
}
</style>

<!-- TODO:
- [ ] Check button color if always green
- [ ] Check gradient idea
-->
